<template>
  <div class="shop">
    <!-- 侧边tab栏 -->
    <!-- tab栏 -->
    <van-tabs v-model="listfrom.rId" sticky @click="onClick" color="#037ffe">
      <van-sidebar
        v-model="activeKey"
        style="position: fixed; overflow-y: scroll; height: 100%;width:95px"
      >
        <van-sidebar-item
          title="全部"
          @click="onSearch(undefined)"
        />
        <van-sidebar-item
          :title="item.Name"
          v-for="item in hangye"
          :key="item.Coding"
          @click="onSearch(item.Coding)"
        />
      </van-sidebar>
      <van-tab
        :title="item.Title"
        :name="item.RId"
        v-for="item in ridlist"
        :key="item.RId"
      >
        <div
          class="information"
          style="
            position: relative;
            left: 25%;
            width: 75%;
            overflow-y: scroll;
            height: 100%;
          "
        >
          <!-- 全部 -->
          <van-list
            v-model="loading"
            :finished="finished"
            offset="50"
            @load="onLoad"
          >
            <div class="center" v-for="item in list" :key="item.index">
              <div class="shopTitle">{{ item.Title }} <div style="float:right;margin-right:10px;color:#999;font-size:14px">{{item.ShopTypeName}}</div> </div>
              <div></div>
              <div class="Content" style="padding: 8px 10px">
                <div style="display: inline-block;font-size:15px;width:95%" class="ContentB">
                  <span style="color: #333;margin-top:10px">
                    <van-icon name="manager" class="icon" v-if="item.Charger" style="padding-right:15px">{{ item.Charger }}</van-icon>
                    <a
                      :href="'tel:' + item.Mobile"
                      style="color: #000; text-decoration: underline;color:#188DFA"
                      ><van-icon name="phone" class="icon" />{{ item.Mobile }}</a
                    >
                  </span>
                  <span style="color: #333;margin:10px 0px" class="van-multi-ellipsis--l3"
                    ><van-icon name="location" class="icon" />地址:{{ item.Address }}</span
                  >
                  <span style="font-size:14px;color:#333">
                    {{ item.Remark }}
                  </span>
                </div>
              </div>
            </div>
          </van-list>
          <!-- 没有更多数据了 -->
          <div v-if="hidden" class="hidden">───── 没有更多数据了 ─────</div>
          <!-- <van-pagination
            style="margin-bottom: 30px"
            v-model="listfrom.page"
            :total-items="listfrom.limit"
            :page-count="page"
            mode="simple"
            @change="getList()"
          /> -->
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import { WeGetBusinessList, WeGetRegionList } from "@/api/yeguang";
import { GetCodeEntrysList } from "@/api/ResumeJS";
export default {
  data() {
    return {
      riKind: 0, //tab栏
      list: {}, //资讯列表
      listfrom: {
        page: 1,
        limit: 10,
        rId: 0,
        typeKind: undefined,
      }, //查询传参
      activeKey: "0",
      hangye: [], //行业
      ridlist: [
        {RId:0,Title:"全部"},
        {RId:83,Title:"小合家"},
        {RId:86,Title:"小鑫家"},
        {RId:87,Title:"小汇家"},
        {RId:88,Title:"小坤家"},
        {RId:81,Title:"小明家"},
      ], //小区
      page: "0",
      search: "", //搜索内容
      hidden: false, //没有更多数据了
      loading: false, //加载
      finished: false, //数据完全加载结束为 true
      total: undefined, //数据条数
    };
  },
  methods: {
    // 获取分页列表
    getList() {
      WeGetBusinessList(this.listfrom)
        .then((res) => {
          console.log(res);
          if (res.data.code == 0) {
            if (res.data.data.length == 0) {
              this.hidden = true;
              this.finished = true;
              this.list = {};
            } else {
              this.hidden = false;
              this.list = res.data.data;
              this.total = res.data.count;
              this.finished = true;
              // this.page = Math.ceil(res.data.count / 10);
            }
          }
        })
    },
    // hangye
    EntrysList() {
      GetCodeEntrysList({ kind: 310 }).then((res) => {
        this.hangye = res.data.data;
      });
      // WeGetRegionList({ organCode: "4103110201" }).then((res) => {
      //   this.ridlist = res.data.data;
      //   for(var i=0;i<this.ridlist.length;i++){
      //     if(this.ridlist[i].Title == "光合城小区"){
      //       this.ridlist[i].Title = "小合家"
      //     }else if(this.ridlist[i].Title == "明鑫花园"){
      //       this.ridlist[i].Title = "小明家"
      //     }else if(this.ridlist[i].Title == "鑫和佳园"){
      //       this.ridlist[i].Title = "小鑫家"
      //     }else if(this.ridlist[i].Title == "乾坤御苑"){
      //       this.ridlist[i].Title = "小坤家"
      //     }else if(this.ridlist[i].Title == "百汇城"){
      //       this.ridlist[i].Title = "小汇家"
      //     }
      //   }
      // });
    },
    // 下拉刷新
    onLoad() {
      // this.listfrom.page = this.listfrom.page + 1;
      // this.getList();
    },
    // tan点击切换事件
    onClick(e) {
      this.listfrom.rId = e;
      this.listfrom.page = 1;
      this.getList();
    },
    // 搜索
    onSearch(e) {
      this.listfrom.typeKind = e;
      this.listfrom.page = 1;
      this.getList();
    },
    // 时间格式化
    dataFormat(value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
  },
  mounted() {
    this.EntrysList();
    this.getList();
  },
};
</script>
<style scoped>
.shopTitle {
  color: #000;
  padding: 10px;
  font-size: 16px;
}
.shop .information .center {
  border-bottom: none;
  padding: 0px;
  border-radius: 5px;
}
.shop .center {
  width: 92%;
  margin: 10px auto;
  background: rgb(246, 243, 243);
}
.hidden {
  text-align: center;
  font-size: 15px;
  color: rgb(173, 169, 169);
  margin: 10px 0px 25px 0px;
}
.icon {
  padding-right: 8px;
}
.van-sidebar-item--select {
  color: #037ffe;
  font-size: 14px;
}
.van-sidebar-item--select::before {
  background-color: #037ffe;
}
</style>